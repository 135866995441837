import React, { useState, useRef, useMemo, useCallback } from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAppStore } from "../appStore";
import axios from "axios";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function AddBlog() {
    const baseURL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const token = useAppStore((state) => state.token);
    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [image2, setImage2] = useState("");
    const [content, setContent] = useState("");
    const [video, setVideo] = useState("");
    const [status, setStatus] = useState("Published");
    const [type, setType] = useState("Blog");
    const [keywords, setKeywords] = useState("");
    const img = useRef("");
    const contentconfig = useMemo(
        () => ({
            readonly: false,
            placeholder: "Enter Content",
        }),
        []
    );

    const createUser = async (url) => {
        let posturl = baseURL + "blog";
        const tid = toast.loading("Please wait...");

        await axios
            .post(
                posturl,
                {
                    name: name,
                    title: title,
                    description: description,
                    image: image,
                    image2: image2,
                    content: content,
                    video: video,
                    keywords: keywords,
                    status: status,
                    type: type,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                toast.update(tid, {
                    render: "Created!",
                    type: "success",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                });
                navigate("/blog");
            })
            .catch(function (error) {
                toast.update(tid, {
                    render: error,
                    type: "error",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                    theme: "light",
                });
                console.log("kcheckpost" + error); //return 429
            });
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleVideoChange = (event) => {
        setVideo(event.target.value);
    };

    const onContentBlur = useCallback(
        (newContent) => {
            setContent(newContent);
        },
        [content]
    );

    const handlePicChange = (event) => {
        console.log(event.target.files);
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setImage(e.target.result);
        };
    };

    const handlePic2Change = (event) => {
        console.log(event.target.files);
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setImage2(e.target.result);
        };
    };

    const handleKeywordChange = (event) => {
        setKeywords(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    return (
        <>
            <ToastContainer />
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Card
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <Typography variant="h5" align="center">
                                Add Blog
                            </Typography>
                            <Box height={10} />
                            <IconButton
                                style={{
                                    position: "absolute",
                                    top: "-10",
                                    left: "20",
                                }}
                                onClick={() => {
                                    navigate("/blog");
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Box height={20} />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        value={name}
                                        onChange={handleNameChange}
                                        label="Name"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                            marginTop: "30px",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={title}
                                        onChange={handleTitleChange}
                                        label="Title"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                            marginTop: "30px",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        variant="outlined"
                                        sx={{ minWidth: "100%" }}
                                        size="small"
                                    >
                                        <InputLabel id="branch-label">
                                            Status*
                                        </InputLabel>
                                        <Select
                                            labelId="branch-label"
                                            size="small"
                                            value={status}
                                            label="Status*"
                                            onChange={handleStatusChange}
                                        >
                                            <MenuItem value="Published">
                                                Published
                                            </MenuItem>
                                            <MenuItem value="Drafted">
                                                Drafted
                                            </MenuItem>
                                            <MenuItem value="Trashed">
                                                Trashed
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        variant="outlined"
                                        sx={{ minWidth: "100%" }}
                                        size="small"
                                    >
                                        <InputLabel id="branch-label">
                                            Type*
                                        </InputLabel>
                                        <Select
                                            labelId="branch-label"
                                            size="small"
                                            value={type}
                                            label="Type*"
                                            onChange={handleTypeChange}
                                        >
                                            <MenuItem value="Blog">
                                                Blog
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        label="Description"
                                        size="small"
                                        sx={{
                                            marginTop: "0px",
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={false}
                                        id="keywords"
                                        name="keywords"
                                        value={keywords}
                                        onChange={handleKeywordChange}
                                        label="Keywords"
                                        size="small"
                                        sx={{
                                            marginTop: "0px",
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={video}
                                        onChange={handleVideoChange}
                                        label="Video"
                                        size="small"
                                        sx={{
                                            marginTop: "0px",
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label htmlFor="">Content</label>
                                    <JoditEditor
                                        value={content}
                                        placeholder="Content"
                                        config={contentconfig}
                                        tabIndex={1}
                                        onBlur={onContentBlur}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <input
                                        type="file"
                                        onChange={handlePicChange}
                                        accept="/image/*"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <input
                                        type="file"
                                        onChange={handlePic2Change}
                                        accept="/image/*"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="center">
                                        <Button
                                            variant="contained"
                                            onClick={createUser}
                                        >
                                            Submit
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Box>
            </div>
        </>
    );
}
